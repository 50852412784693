var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field-row",
    {
      attrs: {
        title: _vm.title,
        tooltip: _vm.tooltip,
        "line-number": _vm.lineNumber,
      },
    },
    [
      _c("v-checkbox", {
        staticClass: "mt-0",
        attrs: {
          label: _vm.inputLabel || _vm.title,
          attach: "",
          clearable: "",
          dense: "",
          value: _vm.$attrs.value,
        },
        on: { change: _vm.onCheckboxValueChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }